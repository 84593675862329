@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Rule of 56 Styles */

@keyframes RingProgress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.ActivityRings {
  height: 100%;
  width: 100%;
}

.ActivityRings .ring {
  transform-origin: 50%;
}

.ActivityRings .completed {
  animation: RingProgress 1s ease-in-out forwards;
  stroke-linecap: round;
}

.ActivityRings circle {
  fill: none;
}

.ring1 .background {
  stroke: transparentize(#7760F0, 0.8);
}

.ring1 .completed {
  stroke: #7760F0;
}

.ring2 .background {
  stroke: transparentize(#BA44AE, 0.8);
}

.ring2 .completed {
  stroke: #BA44AE;
}

.ring3 .background {
  stroke: transparentize(#F3B1D3, 0.8);
}

.ring3 .completed {
  stroke: #F3B1D3;

}
.ring4 .background {
  stroke: transparentize(#98F9FF, 0.8);
}

.ring4 .completed {
  stroke: #98F9FF;

}