.spin-container {
  top: 35%;
}

.spin {
  border: 4px solid #5d7CEA;
  width: 200px;
  height: 200px;
  margin: 5.6px auto;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

.spin-logo {
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
  z-index: 60;
}

.flip {
  animation: mirror 2s ease-in-out infinite;
}


#loader2 {
  border: 3.5px solid #f9acbf;
  width: 220px;
  height: 220px;
  position: relative;
  top: -216px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}
#loader3 {
  border: 3.5px solid #7760f0;
  width: 240px;
  height: 240px;
  position: relative;
  top: -452px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader4 {
  border: 3.5px solid #98F9FF;
  width: 260px;
  height: 260px;
  position: relative;
  top: -708px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#background-transition {
  background-color: rgba(0,0,0,.6);
  -webkit-transition: background-color 1000ms ease-in-out;
    -moz-transition: background-color 1000ms ease-in-out;
    -o-transition: background-color 1000ms ease-in-out;
    -ms-transition: background-color 1000ms ease-in-out;
    transition: background-color 1000ms ease-in-out;
  }

@keyframes rotate {
  0% {
    transform: rotateZ(-360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@keyframes mirror {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(1);
  }
}

.center {
  position: absolute;

}

